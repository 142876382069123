/* SubmissionForm.css */
.body-container {
    background-color: beige;
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center; /* Center the content horizontally */
    align-items: center; /* Center the content vertically */
  }
  
  .submission-form-container {
    position: absolute;
    top: 50%; /* Align the top of the form container with the center of the viewport */
    right: 0;
    transform: translateY(-50%);
    width: 40vw; /* Adjust the width as needed */
    /* max-width: 700px; Adjust the max-width as needed */
    padding: 20px;
  }
   
  .submission-form {
    background-color: #f1f3f4; /* Set form background color */
    padding: 20px;
    border-radius: 8px;
  }
  
  .form-field {
    margin-bottom: 16px;
  }
  
  label {
    display: block;
    margin-bottom: 6px;
    color: #5f6368; /* Set label text color */
  }
  
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  select {
    width: calc(100% - 22px); /* Adjust width to account for padding */
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #dadce0; /* Set input border color */
  }
  
  .submit-button {
    background-color: #1a73e8;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #0c5aa6;
  }
  
  h2 {
    color: rgb(0, 0, 0); /* Set h2 text color to white */
  }
  